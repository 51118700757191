<template>
  <div class="bg-blue absolute-full" style="overflow: hidden">
    <video
      v-if="src"
      class="absolute-center"
      ref="videoPlayer"
      playsinline
      :style="style"
      type="application/x-mpegURL"
    ></video>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from "vue";
import videojs from "video.js";
import "video.js/dist/video-js.css";
defineOptions({ name: "SectionRowNavVideo" });

const props = defineProps({
  poster: { type: String, default: "" },
  src: { type: String, default: "" },
  width: { type: Number, default: 480 },
});

const style = computed(() => {
  return {
    width: `${props.width}px`,
  };
});
const videoPlayer = ref(null);
let player = null;

onMounted(async () => {
  await nextTick();
  player = videojs(videoPlayer.value, {
    autoplay: true,
    controls: false,
    fluid: false,
    loop: true,
    muted: true,
    // poster: props.poster,
    preload: "auto",
    useDevicePixelRatio: true,
  });
  player.src({ src: props.src });
  // player.poster;
  player.load();
});
</script>
