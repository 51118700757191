<template>
  <div>
    <template v-for="section in app.page.sections" :key="section._key">
      <div
        class="bg-red items-center q-pa-md relative-position row"
        :style="style"
      >
        <section-row-nav-video
          :src="section.project.media[0].videoUrl"
          :width="width"
        />
        <div class="relative-position">{{ section.title }}</div>
      </div>
    </template>
    <q-resize-observer @resize="(size) => onResize(size)" />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useAppStore } from "src/stores/app";
import SectionRowNavVideo from "./SectionRowNavVideo.vue";

defineOptions({ name: "SectionRowNav" });

const $q = useQuasar();
const appStore = useAppStore();
const { app } = storeToRefs(appStore);
const height = computed(() => ($q.screen.lt.md ? 123 : 82));
const width = ref(480);

const style = computed(() => {
  return {
    height: `${height.value}px`,
  };
});

const onResize = (size) => {
  width.value = size.width;
};
</script>
